import React, { useState } from 'react';
import '../CSS/SearchBar.css'; // Adjust the path as needed

const SearchBar = ({ data, setResults }) => {
    const [query, setQuery] = useState({
        minAge: '',
        maxAge: '',
        minHeight: '',
        maxHeight: '',
        religion: '',
        annualIncome: '',
        education: '',
        occupation: '',
        maritalStatus: '',
    });

    const handleChange = (e) => {
        setQuery({
            ...query,
            [e.target.name]: e.target.value
        });
    };

    const handleSearch = () => {
        const filteredResults = data.filter((item) => {
            const age = Number(item.age);
            const height = Number(item.height);
            const income = Number(item.annualIncome); // Convert annualIncome to number
            const minIncome = Number(query.annualIncome);
    
            const matchesAge = (!query.minAge || age >= Number(query.minAge)) &&
                (!query.maxAge || age <= Number(query.maxAge));
            const matchesHeight = (!query.minHeight || height >= Number(query.minHeight)) &&
                (!query.maxHeight || height <= Number(query.maxHeight));
            const matchesReligion = !query.religion || (item.religion && item.religion.toLowerCase() === query.religion.toLowerCase());
            const matchesAnnualIncome = (!query.annualIncome || income == minIncome);
            const matchesEducation = !query.education || (item.education && item.education.toLowerCase().trim() === query.education.toLowerCase().trim());
            const matchesOccupation = !query.occupation || (item.occupation && item.occupation.toLowerCase().trim() === query.occupation.toLowerCase().trim());
            const matchesMaritalStatus = !query.maritalStatus || (item.maritalStatus && item.maritalStatus.toLowerCase() === query.maritalStatus.toLowerCase());
    
            return (
                matchesAge &&
                matchesHeight &&
                matchesReligion &&
                matchesAnnualIncome &&
                matchesEducation &&
                matchesOccupation &&
                matchesMaritalStatus
            );
        });
    
        setResults(filteredResults);
    };
    

    return (
        <div className="search-bar">
            <input
                type="number"
                name="minAge"
                value={query.minAge}
                onChange={handleChange}
                placeholder="Min Age"
            />
            <input
                type="number"
                name="maxAge"
                value={query.maxAge}
                onChange={handleChange}
                placeholder="Max Age"
            />
            <input
                type="number"
                name="minHeight"
                value={query.minHeight}
                onChange={handleChange}
                placeholder="Min Height (cm)"
            />
            <input
                type="number"
                name="maxHeight"
                value={query.maxHeight}
                onChange={handleChange}
                placeholder="Max Height (cm)"
            />
            <select
                name="religion"
                value={query.religion}
                onChange={handleChange}
            >
                <option value="">Select Religion</option>
                <option value="Hindu">Hindu</option>
                <option value="Muslim">Muslim</option>
                <option value="Christian">Christian</option>
                <option value="Jain">Jain</option>
                <option value="Sikh">Sikh</option>
                <option value="Buddhist">Buddhist</option>
                <option value="Other">Other</option>
            </select>
            <input
                type="text"
                name="annualIncome"
                value={query.annualIncome}
                onChange={handleChange}
                placeholder="Annual Income"
            />
            <select
                name="education"
                value={query.education}
                onChange={handleChange}
            >
                <option value="">Select Education</option>
                <option value="High School">High School</option>
                <option value="Higher Secondary">Higher Secondary</option>
                <option value="Graduation">Graduation</option>
                <option value="Post Graduation">Post Graduation</option>
            </select>
            <select
                name="occupation"
                value={query.occupation}
                onChange={handleChange}
            >
                <option value="">Select Occupation</option>
                <option value="Businessman">Businessman</option>
                <option value="Salaried Employee">Salaried Employee</option>
                <option value="Self Employed">Self Employed</option>
                <option value="Designer">Designer</option>
                <option value="Doctor">Doctor</option>
                <option value="Marketing Manager">Marketing Manager</option>
                <option value="Other">Other</option>
            </select>
            <select
                name="maritalStatus"
                value={query.maritalStatus}
                onChange={handleChange}
            >
                <option value="">Select Marital Status</option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
            </select>
            <button onClick={handleSearch}>Search</button>
        </div>
    );
};

export default SearchBar;
