import axios from "axios";
console.log("API Base URL.....:", process.env.REACT_APP_API_URL);

const axiosURL = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
      },
});
console.log("API Base URL:..", process.env.REACT_APP_API_URL);

// Adding Authorization token to requests
axiosURL.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = token;
  }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  
export default axiosURL;

