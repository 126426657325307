import React from 'react';
import '../CSS/AboutUs.css';
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import {Carousel} from 'react-bootstrap'; // Import Bootstrap Carousel component
import mehendi from '../Images/mehendi.jpg';
import couple from '../Images/couple.jpg';
import ring from '../Images/ring.jpg';
import coupleCircleImage1 from '../Images/couple1.jpg'; // Add the image for the circular section
import coupleCircleImage2 from '../Images/couple2.jpg'; // Add the image for the circular section
import coupleCircleImage3 from '../Images/couple3.jpg'; // Add the image for the circular section
import coupleCircleImage4 from '../Images/couple4.jpg'; // Add the image for the circular section
import Footer from './Footer';
import Navbar from './Navbar';


const AboutUs = () => {
    return (
        <>
        <Navbar/>
        <div className="about-us-container">
            <h1>About Us</h1>
            
            {/* Bootstrap Carousel with Images */}
            <Carousel className="about-us-carousel">
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={mehendi}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <p  className="textcolor-change">Helping people find their perfect match...</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={couple}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <p className="textcolor-change">Bringing people together based on shared values...</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={ring}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <p className="textcolor-change">Years of experience in providing matchmaking services...</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            {/* Website Information Section */}
            <div className="website-info-section">
                <h2>Why Choose Our Matrimonial Website?</h2>
                <p>
                    Our platform combines traditional values with modern technology to help you find your life partner. We ensure a secure and user-friendly experience for a smooth journey toward finding your match.
                </p>
                <div className="info-cards">
                    <div className="info-card">
                        <h3>Extensive Database</h3>
                        <p>Access a large pool of verified profiles that match your preferences and criteria.</p>
                    </div>
                    <div className="info-card">
                        <h3>Advanced Search</h3>
                        <p>Utilize our advanced search filters to find the perfect match.</p>
                    </div>
                    <div className="info-card">
                        <h3>Privacy & Security</h3>
                        <p>Your data is secure with us, and we prioritize your privacy at every step.</p>
                    </div>
                </div>
            </div>

            {/* Pyramid Structure with Circular Descriptions */}
            <div className="pyramid-structure">
                <div className="circle top-circle">
                    <img src={coupleCircleImage1} alt="Top Circle" />
                    <div className="circle-overlay">
                        <h4>Happy Moments</h4>
                        <p className="textcolor-change">Sakshi Weds Rajesh</p>
                    </div>
                </div>
                <div className="middle-row">
                    <div className="circle">
                        <img src={coupleCircleImage2} alt="Left Circle" />
                        <div className="circle-overlay">
                            <h4>Happy Moments</h4>
                            <p className="textcolor-change">Payal Weds Raj</p>
                        </div>
                    </div>
                    <div className="circle">
                        <img src={coupleCircleImage3} alt="Right Circle" />
                        <div className="circle-overlay">
                            <h4>Happy Moments</h4>
                            <p className="textcolor-change">Priya Weds Kapil</p>
                        </div>
                    </div>
                </div>
                <div className="circle bottom-circle">
                    <img src={coupleCircleImage4} alt="Bottom Circle" />
                    <div className="circle-overlay">
                        <h4>Happy Moments</h4>
                        <p className="textcolor-change">Pinki Weds Vishal</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    );
};

export default AboutUs;
