import React from 'react';
import { useParams } from 'react-router-dom';
import '../CSS/ProfileDetails.css';

const ProfileDetails = () => {
    const { id } = useParams();

    // Fetch profile details using the id (Mock data for now)
    const profile = {
        id: id,
        name: 'John Doe',
        age: 30,
        location: 'New York',
        bio: 'Lorem ipsum dolor sit amet...',
        image: '/path/to/image1.jpg',
    };

    return (
        <div className="profile-details">
            <img src={profile.image} alt={profile.name} />
            <h2>{profile.name}</h2>
            <p>Age: {profile.age}</p>
            <p>Location: {profile.location}</p>
            <p>Bio: {profile.bio}</p>
        </div>
    );
};

export default ProfileDetails;
