import React from 'react';
import RegistrationForm from './RegistrationForm';
import '../CSS/Registration.css';
import Footer from './Footer';

const Registration = () => {
    return (
        <>
        <div>
       
            <RegistrationForm />
        </div>
        <Footer/>
        </>
    );
};

export default Registration;
