import React , { useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../CSS/PayNow.css';
import axiosURL from '../axiosURL';
import Navbar from './Navbar';

const PayNow = () => {
   
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: location.state?.name || '',
    email: location.state?.email || '',
    contact: location.state?.mobile || '',
    amount: location.state?.amount || ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const orderResponse = await axiosURL.post('/payments/createOrder', formData);
      const { email, rzpOrderId, amount } = orderResponse.data;
      console.log('Order Response:', orderResponse.data);
      
      const options = {
        key: 'rzp_test_oN4JcNZAAsWYTN', // Use your Razorpay test/live API key
        amount: (amount * 100).toString(), // Amount in paise
        currency: 'INR',
        name: 'RistoKiDor',
        description: 'Upgrade',
        order_id: rzpOrderId,
        receipt: email,
        handler: async function (response) {
          console.log('Razorpay Response:', response);
          try {
            const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
            await axiosURL.post('/payments/verifyPayment', {
              rzpPaymentId: razorpay_payment_id,
              rzpOrderId: razorpay_order_id,
              rzpSignature: razorpay_signature
            });
            alert('Payment successful');

             // Update subscription status after successful payment
             const storedUserId = localStorage.getItem('userId'); // Replace with your logic
             const updateSubscription = await axiosURL.put(`/userSubscription/${storedUserId}` ,{
              subscriptionStatus : formData.selectedPlan === 0 || formData.selectedPlan === 1 ? 'Premium' : 'Exclusive'
             });
             console.log('updateSubscription' , updateSubscription);
            navigate('/'); // Redirect to a success page or show a success message
          } catch (error) {
            console.error('Error verifying payment:', error);
            alert('Payment verification failed. Please try again.');
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.contact
        },
        theme: {
          color: '#3399cc'
        }
      };
      console.log('Rzp.....' , window.Razorpay);

      const rzp = new window.Razorpay(options);
      rzp.on('payment.failed', function (response) {
        console.error('Payment failed:', response.error);
        alert('Payment failed. Please try again.');
      });
      rzp.open();

    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      alert('An error occurred. Please try again.');
    }
  }
  return (
    <>
    <Navbar/>
      <div className="login-container">
        <div className="login-form">
          <h1>Payment Form</h1>
          <form onSubmit={handleSubmit}>
          <label><b>Name</b></label>
          <input type="name" placeholder="Name" id="name" name="name" value={formData.name} onChange={handleChange} />
            <label><b>Email</b></label>
            <input type="email" placeholder="Email" id="email" name="email" value={formData.email} onChange={handleChange} />
            <label><b>Contact</b></label>
            <input type="tel" id="contact" name="contact" value={formData.contact} onChange={handleChange}></input>
            
          <label><b>Amount</b></label>
            <input type="Number" placeholder="Enter the Amount" id="amount" name="amount" value={formData.amount} onChange={handleChange}/>
          <button type="submit" className="btn btn-secondary m-3">Pay Now</button> 
 
          </form>
        </div>
      </div>
    </>
  );
}

export default PayNow;
