

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/ProfileDetails.css';
import Footer from './Footer';
import Navbar from './Navbar';
import axiosURL from '../axiosURL';

const ProfilePage = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({});
  const [matchSuggestions, setMatchSuggestions] = useState([]);
  const [showComparisonChart, setShowComparisonChart] = useState(false);

  // Example personal info and match suggestions for demo purposes
  const dummyPersonalInfo = {
    name: 'John Doe',
    age: 30,
    gender: 'Male',
    lookingFor: 'Female',
    bio: 'I love traveling and reading.',
  };

  const dummyMatches = [
    { id: 1, name: 'Jane Doe', age: 28, description: 'Loves painting and music.' },
    { id: 2, name: 'Alice Smith', age: 27, description: 'Enjoys hiking and yoga.' },
  ];

  useEffect(() =>{
    // Simulate generating userId or retrieve it from localStorage/session
    const storedUserId = localStorage.getItem('userId');
    setUserId(storedUserId);

    if(storedUserId){
      // Fetch actual user data from the backend using userId

      const fetchUserProfile = async () => {

        try {
          const response = await axiosURL.get(`/user/${storedUserId}`); // Make sure this is your actual API endpoint
          console.log('response Data . : ', response.data);
          setPersonalInfo(response.data); // Store fetched user data
         
          console.log('personal info  : ', response.data.firstName);
          setMatchSuggestions(dummyMatches);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
       };

        // Fetch match suggestions (optional)
      //const fetchMatchSuggestions = async () => {
        //try {
         // const response = await axiosURL.get(`/user/${storedUserId}/matches`);
          //setMatchSuggestions(response.data); // Assuming the API returns match suggestions
       // } catch (error) {
         // console.error('Error fetching match suggestions:', error);
        //}
      //};

       fetchUserProfile();
       //fetchMatchSuggestions();
    }else {
      // If no userId is found, redirect to login
      navigate('/login');
    }
  }, [navigate]);

  const handleUpgrade = () => {
    setShowComparisonChart(true);
  };

  

  return (
    <>
    <Navbar/>
    <div style={{ display: 'flex', padding: '20px', gap: '20px' }}>
      {/* Section 1: User ID and Personal Info */}
      <div style={{ flex: 1, border: '1px solid #ddd', padding: '10px' }}>
        <h3>User ID: {userId}</h3>
        <button onClick={() => setShowPersonalInfo(!showPersonalInfo)}>
          Personal Info
        </button>
        {showPersonalInfo && (
          <div>
            <p><strong>Name:</strong> {personalInfo.firstName}</p>
            <p><strong>Age:</strong> {personalInfo.age}</p>
            <p><strong>Gender:</strong> {personalInfo.gender}</p>
            <p><strong>Looking For:</strong> {personalInfo.lookingFor}</p>
            <p><strong>Bio:</strong> {personalInfo.userAddtionalDetails.smallBio}</p>
          </div>
        )}
      </div>

      {!showComparisonChart ? (
        <>
          {/* Section 2: Match Suggestions */}
          <div style={{ flex: 2, border: '1px solid #ddd', padding: '10px' }}>
            <h3>Match Suggestions</h3>
            {matchSuggestions.map(match => (
              <div key={match.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <img src={`https://via.placeholder.com/50`} alt="Profile" style={{ borderRadius: '50%', marginRight: '10px' }} />
                <div>
                  <h4>{match.name}, {match.age}</h4>
                  <p>{match.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Section 3: Premium Plans */}
          <div style={{ flex: 1, border: '1px solid #ddd', padding: '10px' }}>
            <h3>Premium Plans</h3>
            <p>Get more visibility and connect with more matches by going premium!</p>
            <button onClick={handleUpgrade}>Go for Premium</button>
          </div>
        </>
      ) : (
        <div style={{ flex: 3, border: '1px solid #ddd', padding: '10px' }}>
          <h3>Upgrade Membership</h3>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Feature</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Premium Plan</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Exclusive Plan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Limited Calls</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>10</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>20</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Sharing Contact Details</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Yes</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Yes</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Advanced Search</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Yes</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Yes</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Super Interest</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>20</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>40</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Contact views</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>20</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>60</td>
              </tr>
            </tbody>
          </table>
          <hr style={{ margin: '20px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1, textAlign: 'center', padding: '10px', border: '1px solid #ddd' }}>
              <h4>1 Month</h4>
              <p>Rs 999</p>
            </div>
            <div style={{ flex: 1, textAlign: 'center', padding: '10px', border: '1px solid #ddd' }}>
              <h4>3 Months</h4>
              <p>Rs 2499</p>
            </div>
            <div style={{ flex: 1, textAlign: 'center', padding: '10px', border: '1px solid #ddd' }}>
              <h4>Till Marriage</h4>
              <p>Rs 7999</p>
            </div>
          </div>
        </div>
      )}
    </div>
    <Footer/>
    </>
  );
};

export default ProfilePage;
