
import React, { useState, useEffect } from 'react';
import '../CSS/FullProf.css'; 
import { useLocation, useNavigate } from 'react-router-dom';

const FullProf = () => {
  const location = useLocation();
  const userProfileData = location.state;
  const navigate = useNavigate();

  const profile = {
    photo: userProfileData?.profilePhoto || 'path/to/default/photo.jpg',
    name: `${userProfileData?.firstName || ''} ${userProfileData?.lastName || ''}`,
    height: userProfileData?.userAddtionalDetails?.height || 'N/A',
    education: userProfileData?.userAddtionalDetails?.occupation || 'N/A',
    caste: userProfileData?.userAddtionalDetails?.caste || 'N/A',
    state: userProfileData?.userAddtionalDetails?.state || 'N/A',
    income: userProfileData?.userAddtionalDetails?.annualIncome || 'N/A',
    about: userProfileData?.userAddtionalDetails?.aboutYourself || 'N/A',
    hobbies: userProfileData?.userAddtionalDetails?.hobbies || 'N/A',
    lifestyle: userProfileData?.userAddtionalDetails?.lifestyle || 'N/A',
    background: userProfileData?.userAddtionalDetails?.background || 'N/A',
    contactEmail: userProfileData?.email || 'N/A',
    contactPhone: userProfileData?.userAddtionalDetails?.mobileNo || 'N/A'
};
 console.log( 'profile data' , profile);
  // Simulated user data from the backend

  const handleUpgradeClick = () => {
    navigate('/upgrade-membership'); // Navigate to the Upgrade page
  };

  // If you want to simulate an API call to get data, use useEffect.
  
  return (
    <div className="profile-page">
      <div className="profile-container">
        {/* Left Side: Profile Photo */}
        <div className="profile-photo">
        {profile.photo ? (
                          <img 
                            src={`data:${profile.photo};base64,${profile.photo}`} 
                            alt={`${profile.name}'s Profile`}
                           />
                          ) : (
                            <p>No Profile Photo</p>
                              )}
        </div>

        {/* Right Side: User Information */}
        <div className="profile-info">
          <h2 className="profile-name">{profile.name}</h2>
          <ul className="profile-details">
            <li><strong>Height:</strong> {profile.height}</li>
            <li><strong>Education:</strong> {profile.education}</li>
            <li><strong>Caste:</strong> {profile.caste}</li>
            <li><strong>State:</strong> {profile.state}</li>
            <li><strong>Income:</strong> {profile.income}</li>
          </ul>

          {/* Sections */}
          <div className="profile-sections">
            <section>
              <h3>About Yourself</h3>
              <p>{profile.about}</p>
            </section>

            <section>
              <h3>Hobbies & Interests</h3>
              <p>{profile.hobbies}</p>
            </section>

            <section>
              <h3>Lifestyle</h3>
              <p>{profile.lifestyle}</p>
            </section>

            <section>
              <h3>Background</h3>
              <p>{profile.background}</p>
            </section>

            <section>
              <h3>Contact Details</h3>
              <p>Email: {profile.contactEmail} | Phone: {profile.contactPhone}</p>
            </section>
          </div>

          {/* Upgrade Now Button */}
          <button className="upgrade-button" onClick={handleUpgradeClick}>
            Upgrade Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default FullProf;
