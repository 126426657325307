import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import '../CSS/RegistrationForm.css';
import Navbar from './Navbar';
import axiosURL from '../axiosURL';

const RegistrationForm = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        age: '',
        email: '',
        password: '',
        gender: '',
        lookingFor: '', // Changed from object to single string
        religion: '',
        education: '',
        country: ''
    });

    const [profilePhoto, setProfilePhoto] = useState(null);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                lookingFor: checked ? value : '', // Checkbox handles either "male" or "female"
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProfilePhoto(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Registration data:', formData);

        const formDataObj = new FormData();

        formDataObj.append('profileData', JSON.stringify(formData));
        if(profilePhoto){
            formDataObj.append('profilePhoto' , profilePhoto);
        }

        try {
            // post changed to get here
            const response = await axiosURL.post('/user/register', formDataObj , {
                headers : {
                    'Content-Type' : 'multipart/form-data',
                },
            });

            console.log('response Status : ',response.status);

            if(response.status === 201){
               console.log('Response..',response);
            // Simulate form submission
            setTimeout(() => {
                alert('User registered successfully');
                console.log('User registered successfully');
                navigate('/login'); // Redirect to the login page
            }, 500);
            }
            else {
                // Handle other status codes if necessary
                console.error('Unexpected status code:', response.status);
                alert('Registration failed! Please try again.');
            }
        } catch (error) {
            alert('Email is already in use !');
            console.error('Email is already in use !', error);
      
        }
    
    };

    return (
        <>
        <div className='regform'>
        <Navbar/>
        
        <form onSubmit={handleSubmit} encType="multipart/form-data" className='Reg-form'>
        <h1>Register</h1>
            <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} />
            <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} />
            <input type="number" name="age" placeholder="Age" onChange={handleChange} />
            <input type="email" name="email" placeholder="Email" onChange={handleChange} />
            <input type="password" name="password" placeholder="Password" onChange={handleChange} />

            <div className="form-group">
                                <label htmlFor="adhar">Upload Profile photo</label>
                                <input type="file" className="form-control-file" id="profilePhoto" name="profilePhoto" onChange={handleFileChange} />
                            </div>

            <div className="gender-section">
                <label>Gender:</label>
                <div>
                    <label>
                        <input type="radio" name="gender" value="male" onChange={handleChange} /> Male
                    </label>
                    <label>
                        <input type="radio" name="gender" value="female" onChange={handleChange} /> Female
                    </label>
                </div>
            </div>

            <div className="looking-for-section">
                    <label>Looking for:</label>
                    <div>
                        <label>
                            <input type="radio" name="lookingFor" value="male" checked={formData.lookingFor === 'male'} onChange={handleChange} /> Male
                        </label>
                        <label>
                            <input type="radio" name="lookingFor" value="female" checked={formData.lookingFor === 'female'} onChange={handleChange} /> Female
                        </label>
                    </div>
                </div>

            <div className="dropdown-section">
                <label>Religion:</label>
                <select name="religion" onChange={handleChange}>
                    <option value="">Select Religion</option>
                    <option value="hindu">Hindu</option>
                    <option value="muslim">Muslim</option>
                    <option value="christian">Christian</option>
                    <option value="sikh">Sikh</option>
                    <option value="jain">Jain</option>
                    <option value="buddhist">Buddhist</option>
                    <option value="other">Other</option>
                </select>
            </div>

            <div className="dropdown-section">
                <label>Education:</label>
                <select name="education" onChange={handleChange}>
                    <option value="">Select Education</option>
                    <option value="highSchool">High School</option>
                    <option value="bachelors">Bachelor's Degree</option>
                    <option value="masters">Master's Degree</option>
                    <option value="phd">PhD</option>
                    <option value="other">Other</option>
                </select>
            </div>

            <div className="dropdown-section">
                <label>Country:</label>
                <select name="country" onChange={handleChange}>
                    <option value="">Select Country</option>
                    <option value="india">India</option>
                    <option value="usa">USA</option>
                    <option value="uk">UK</option>
                    <option value="canada">Canada</option>
                    <option value="australia">Australia</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <button type="submit">Register</button>
        </form>
        </div>
        </>
    );

};

export default RegistrationForm;
