import React from 'react';
import '../CSS/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer>
            <div className="footer-sections">
                <div className="footer-section">
                    <h2>Explore</h2>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                        <li><a href="#blog">Blog</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h2>Services</h2>
                    <ul>
                        <li><a href="#matchmaking">Matchmaking</a></li>
                        <li><a href="#premium">Premium Plans</a></li>
                        <li><a href="#success-stories">Success Stories</a></li>
                        <li><a href="#support">Customer Support</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h2>Help</h2>
                    <ul>
                        <li><a href="#faq">FAQ</a></li>
                        <li><a href="#privacy">Privacy Policy</a></li>
                        <li><a href="#terms">Terms & Conditions</a></li>
                        <li><a href="#support">Help Center</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h2>Legal</h2>
                    <ul>
                        <li><a href="#disclaimer">Disclaimer</a></li>
                        <li><a href="#copyright">Copyright</a></li>
                        <li><a href="#compliance">Compliance</a></li>
                        <li><a href="#report">Report Abuse</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="app-available">
                    <h3>App Available Now</h3>
                    <p>Download our app from the Play Store or App Store.</p>
                </div>
                <div className="follow-us">
                    <h3>Follow Us On</h3>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/login" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://twitter.com/login" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://www.instagram.com/accounts/login/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.linkedin.com/login" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                    </div>
                </div>
            </div>
            <p className="footer-copy">&copy; 2024 All rights reserved by Kaushalyatech Solutions</p>
        </footer>
    );
};

export default Footer;
