import React, { useState } from 'react';
import '../CSS/ContactUs.css'; // Ensure the path matches your project structure
import Footer from './Footer';
import Navbar from './Navbar';
import axiosURL from '../axiosURL';
const ContactUs = () => {
     const initialFormdata = {
        name: '',
        email: '',
        mobile: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormdata);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosURL.post('/contact/save-contact' , formData , {
            headers: {
            'Content-Type' : 'application/json'
                }
            });
            if(response.status === 200){
                alert('Your message has been submitted successfully!');
                setFormData(initialFormdata);
                } else{
                    alert('failed to  submitted contact Details!!!');
                }
        } catch (error) {
            console.error('Error:', error);
            alert('Error saving contact details.');
        }
        
        // You can add more actions here, like sending the form data to a backend server
    };

    return (
        <>
        <Navbar/>
        <div className="contact-us-container">
            <h1>Contact Us</h1>
            <form onSubmit={handleSubmit} className='abc'>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Mobile No:</label>
                    <input
                        type="number"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <button type="submit">Submit</button>
            </form>
            <div className="contact-info">
                <h3>Have any queries?</h3>
                <p>You can connect with us directly at: <a href="mailto:example@example.com">info@rishtonkidor.in</a></p>
            </div>
        </div>
        <Footer/>
        </>
    );
};

export default ContactUs;
