
import React, { useState , useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/UpgradeMembership.css';
import axiosURL from '../axiosURL';

const UpgradeMembership = () => {
  const navigate = useNavigate();
  const [upgradeMode, setUpgradeMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    selectedPlan: '',
  });

  const plans = [
    { purpose: 'Premium - 1 Month', amount: 599 },
    { purpose: 'Premium - 3 Months', amount: 1499 },
    { purpose: 'Premium - Till Marriage', amount: 6999 },
    { purpose: 'Exclusive - 1 Month', amount: 1299 },
    { purpose: 'Exclusive - 3 Months', amount: 3499 },
    { purpose: 'Exclusive - Till Marriage', amount: 8999 },
  ];

  // UseEffect to pre-fill the form with current user's data when component mounts
  useEffect(() => {
    // Example: Get user data from localStorage or an API
    const storedUserId = localStorage.getItem('userId');

    console.log('Stored User Profile ...',storedUserId);

    if(storedUserId){
      // Fetch actual user data from the backend using userId
      
      const fetchUserProfile = async () =>{

          try {
              const response = await axiosURL.get(`/user/${storedUserId}`); // Make sure this is your actual API endpoint
              console.log('profile response..by user Id .' , response.data);
              
               // Update formData with user's name and email if they exist
          if (response.data.firstName && response.data.email) {
            setFormData((prevData) => ({
            ...prevData,
            name: `${response.data.firstName} ${response.data.lastName}`,
            email: response.data.email,
            mobile: response.data.userAddtionalDetails.mobileNo,
          }));
        }
          } catch (error) {
              console.error('Error fetching user profile:', error);
          }
      };

      fetchUserProfile();
  }else {
      // If no userId is found, redirect to login
      alert('user data not found');
  }

   
  }, [navigate]); // Empty dependency array means this runs once on component mount

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpgradeClick = () => {
    setUpgradeMode(true);
  };

  return (
    <div className="upgrade-membership">
      {!upgradeMode ? (
        <section className="upgrade-section">
          <h2>Upgrade Membership</h2>
          <div className="comparison-chart">
            <table>
              <thead>
                <tr>
                  <th>Benefits</th>
                  <th>Premium Plan</th>
                  <th>Exclusive Plan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Limited Calls</td>
                  <td>10</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>Sharing Contact Details</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Advanced Search</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Super Interest</td>
                  <td>20</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>Contact Views</td>
                  <td>20</td>
                  <td>60</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          {/* Form Section */}

          <div className="membership-form">
            
            <h3 className='sizereduce'>Enter Your Details to Upgrade</h3>
           
            <form>
              <label>
                <b>Name</b>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                <b>Email</b>
                <input 
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                <b>Mobile</b>
                <input 
                  type="number"
                  name="mobileNo"
                  placeholder="Enter your Contact"
                  value={formData.mobile}
                  onChange={handleFormChange}
                  required
                />
              </label>
              <label>
                <b>Select Membership Plan</b>
                <select
                  name="selectedPlan"
                  value={formData.selectedPlan}
                  onChange={handleFormChange}
                >
                  <option value="">Select a Plan</option>
                  {plans.map((plan, index) => (
                    <option key={index} value={index}>
                      {plan.purpose} - ₹{plan.amount}
                    </option>
                  ))}
                </select>
              </label>

              {formData.selectedPlan !== '' && (
                <Link
                  to={{
                    pathname: '/PayNow',
                  }}
                  state={{
                    name: formData.name,
                    email: formData.email,
                    mobile:formData.mobile,
                    amount: plans[formData.selectedPlan].amount,
                    purpose: plans[formData.selectedPlan].purpose,
                  }}
                  className="btn btn-secondary"
                >
                  Proceed to Pay
                </Link>
              )}
            </form>
          </div>
          </section>
      ) : (
        <div>
        {/* Upgrade Plan Section */}
        <h2>Go For Premium or Exclusive</h2>
        <button className="btn btn-primary" onClick={handleUpgradeClick}>
          Upgrade Now
        </button>
      </div>
      )}
      </div>
  );
};

export default UpgradeMembership;
