// export default HomePage;
import React, { useState } from 'react';
import '../CSS/HomePage.css';
import Card1 from "../Images/purple_couple.jpg"

import Card2 from "../Images/red_punjabi_couple.jpg";
import Card3 from "../Images/purple_new_s_couple.jpg";
import Card4 from "../Images/punjabi_green_couple.jpg";
import Card5 from "../Images/blue_couple.jpg";
import Card6 from "../Images/new_green_couple_ai.jpg";
import Footer from './Footer';
import Navbar from './Navbar';

const cardData = [
    [
        { id: 1, image: Card1, title: 'Sonal Weds Kapil', description: 'Destined partners unite through perfect match' },
        { id: 2, image: Card2, title: 'Sakshi Weds Shubham', description: 'Together forever, through a perfect match.' },
        { id: 3, image: Card3, title: 'Sonalika Weds Tipendra', description: 'A bond made in heaven, perfect and true' }
    ],
    [
        { id: 4, image: Card4, title: 'Rashi Weds Kartik', description: 'Matched by destiny, forever in love.' },
        { id: 5, image: Card5, title: 'Renuka Weds Kishan', description: 'Bound by destiny, in a perfect union.' },
        { id: 6, image: Card6, title: 'Rashmi Weds Naman', description: 'Two hearts aligned, in a perfect match.' }
    ]
];

const HomePage = () => {
    const [currentCards, setCurrentCards] = useState(0);

    const handleNext = () => {
        setCurrentCards((prev) => (prev + 1) % cardData.length);
    };

    const handlePrev = () => {
        setCurrentCards((prev) => (prev === 0 ? cardData.length - 1 : prev - 1));
    };

    return (
        <>
        <Navbar/>
        <div className='home-background'>
            <div className="homepage">
                <div className="homepage-content">
                    <h1>Welcome to Rishton Ki Dor</h1>
                    <p>Your journey to find a life partner begins here.</p>
                </div>
            </div>
            <div className="cards-container-wrapper">
                <button className="arrow-button left" onClick={handlePrev}>&lt;</button>
                <div className="cards-container">
                    {cardData[currentCards].map(card => (
                        <div className="card" key={card.id}>
                            <img src={card.image} alt={card.title} />
                            <h2>{card.title}</h2>
                            <p>{card.description}</p>
                        </div>
                    ))}
                </div>
                <button className="arrow-button right" onClick={handleNext}>&gt;</button>
            </div>
            
            {/* New Section - Services Offered */}
            <div className="services-section">
                <h2>Why Choose Us?</h2>
                <div className="services">
                    <div className="service-card">
                        <center><h3>Personalized Matchmaking</h3></center>
                        <p>Our expert matchmakers carefully curate matches based on your preferences and lifestyle.</p>
                    </div>
                    <div className="service-card">
                    <center> <h3>Verified Profiles</h3></center>
                        <p>All profiles are verified to ensure authenticity and serious intent.</p>
                    </div>
                    <div className="service-card">
                    <center><h3>Privacy and Security</h3></center>
                        <p>Your personal details are kept secure and are only shared with your consent.</p>
                    </div>
                    <div className="service-card">
                    <center><h3>Dedicated Support</h3></center>
                        <p>Our support team is available 24/7 to assist you with your queries and concerns.</p>
                    </div>
                </div>
            </div>
            </div>
            <Footer/>
        </>
    );
};

export default HomePage;
