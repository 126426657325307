
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../CSS/Login.css';
import Footer from './Footer';
import Navbar from './Navbar';
import axiosURL from '../axiosURL';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosURL.post('/user/login' ,formData);
            
            if(response.status === 200){
               
                const token = response.data.jwt; // Assuming the backend returns the token in response.data.token
                console.log('token..', token);

                const userId = response.data.userId; // Get the userId
                console.log('User Id ...', userId);


                 // Store the token in localStorage with 'Bearer ' prefix
                 localStorage.setItem('token', `Bearer ${token}`); 

                 // Store the userId  in localStorage
                 localStorage.setItem('userId', userId);
                
                alert('Login Successful!');
                console.log('login successfully...');
                 navigate('/additionaldetails'); // Redirect to "Additional Details" page on successful login
            }else{
                // Handle other status codes if necessary
                console.error('Unexpected status code:', response.status);
                alert('Login  failed! Please try again.');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert('Invalid Email or Password!');
                console.log('Invalid Email or Password!');
            }else {
                // Log general error if no response is available
                console.error('Login Error:', error.message);
                alert('Login Error!');
            }
        }
    };

    return (
        <>
    {/* <Navbar1/> */}
    <Navbar/>
            <div className="login-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <h1>Login</h1>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit">Login</button>
                    <p className="forgot-password">
                        <Link to="/forgot-password" >Forgot Password?</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/register" className='reg' >Registration</Link>  
                    </p>
                </form>
            </div>
            <Footer />
        </>
    );
};

export default Login;

