// import React, { useEffect, useState } from 'react';
// import '../CSS/AdditionalDetailsPage.css';
// import Navbar from './Navbar';
// import axiosURL from '../axiosURL';
// import { useNavigate } from 'react-router-dom';

// const AdditionalDetailsPage = () => {
//     const navigate = useNavigate();
//     const [formData, setFormData] = useState({
//         height: '',
//         annualIncome: '',
//         occupation: '',
//         smallBio: '',
//         aboutYourself: '',
//         hobbies: [],
//         interests: [],
//         lifestyle: [],
//         otherHobby: '',
//         otherInterest: '',
//         otherLifestyle: '',
//         background: '',
//         state: '',
//         city: '',
//         caste: '',
//         mobileNo: '',
//     });

//     const [userData, setUserData] = useState(null);
//     const [selectedHobbies, setSelectedHobbies] = useState([]);
//     const [selectedInterests, setSelectedInterests] = useState([]);
//     const [selectedLifestyle, setSelectedLifestyle] = useState([]);

//     const hobbiesList = ['Reading', 'Traveling', 'Cooking', 'Sports', 'Music'];
//     const interestsList = ['Technology', 'Art', 'Fitness', 'Nature', 'Science'];
//     const lifestyleList = ['Healthy Eating', 'Outdoor Activities', 'Minimalist', 'Luxury', 'Traditional'];

    

//     const handleChange = (e) => {
//         const { name, value, type, files, checked } = e.target;

//         if (type === 'checkbox') {
//             const { checked, value } = e.target;
//             if (name === 'hobbies') {
//                 setSelectedHobbies((prev) =>
//                     checked
//                         ? [...prev, value]
//                         : prev.filter((item) => item !== value)
//                 );
//             } else if (name === 'interests') {
//                 setSelectedInterests((prev) =>
//                     checked
//                         ? [...prev, value]
//                         : prev.filter((item) => item !== value)
//                 );
//             } else if (name === 'lifestyle') {
//                 setSelectedLifestyle((prev) =>
//                     checked
//                         ? [...prev, value]
//                         : prev.filter((item) => item !== value)
//                 );
//             }
//         } else {
//             setFormData({ ...formData, [name]: value });
//         }
//     };

//     const handleOtherOptionChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log('Additional details data:', formData);

//         // Prepare data to send
//     const dataToSend = {
//         ...formData,
//         hobbies: selectedHobbies.join(','), // Convert array to comma-separated string
//         interests: selectedInterests.join(','),
//         lifestyle: selectedLifestyle.join(','),
//     };
    
//     console.log('Additional details data to send ...:', dataToSend);

//         try {
//              const response = await axiosURL.post('/user/user-additional-details', dataToSend);
//             console.log('response : ' ,response);
//             // Simulate form submission
//         setTimeout(() => {
//             alert('Additional details submitted successfully');
//             navigate('/profilesection');
//         }, 500);
//         } catch (error) {
//             console.error('Error submitting additional details:', error);
//         }
       
//     };

//     return (
//         <>
//         <Navbar/>
//         <form onSubmit={handleSubmit} className="additional-details-form" >
//             <h1>Additional Details</h1>

//             {/* Height Input */}
//             <div className="input-section">
//                 <label>Height (in cm):</label>
//                 <input type="number" name="height" value={formData.height} onChange={handleChange} />
//             </div>

//             {/* Annual Income Input */}
//             <div className="input-section">
//                 <label>Annual Income:</label>
//                 <input type="number" name="annualIncome" value={formData.annualIncome} onChange={handleChange} />
//             </div>

//             {/* Occupation Input */}
//             <div className="input-section">
//                 <label>Occupation:</label>
//                 <input type="text" name="occupation" value={formData.occupation} onChange={handleChange} />
//             </div>

//             {/* Small Bio Input */}
//             <div className="input-section">
//                 <label>Small Bio (10 words):</label>
//                 <input type="text" name="smallBio" value={formData.smallBio} onChange={handleChange} />
//             </div>

//             {/* About Yourself Input */}
//             <div className="input-section">
//                 <label>About Yourself (50-100 words):</label>
//                 <textarea name="aboutYourself" value={formData.aboutYourself} onChange={handleChange} />
//             </div>

//             {/* Hobbies Section */}
//             <div className="checkbox-section">
//                 <label>Hobbies:</label>
//                 {hobbiesList.map((hobby) => (
//                     <div key={hobby} className="checkbox-item">
//                         <label>
//                             <input
//                                 type="checkbox"
//                                 name="hobbies"
//                                 value={hobby}
//                                 checked={selectedHobbies.includes(hobby)}
//                                 onChange={handleChange}
//                             />
//                             {hobby}
//                         </label>
//                     </div>
//                 ))}
//                 <div className="checkbox-item">
//                     <label>
//                         <input
//                             type="checkbox"
//                             name="hobbies"
//                             value="Other"
//                             checked={selectedHobbies.includes('Other')}
//                             onChange={handleChange}
//                         />
//                         Other
//                     </label>
//                     {selectedHobbies.includes('Other') && (
//                         <input
//                             type="text"
//                             name="otherHobby"
//                             value={formData.otherHobby}
//                             onChange={handleOtherOptionChange}
//                             placeholder="Please specify"
//                         />
//                     )}
//                 </div>
//             </div>

//             {/* Interests Section */}
//             <div className="checkbox-section">
//                 <label>Interests:</label>
//                 {interestsList.map((interest) => (
//                     <div key={interest} className="checkbox-item">
//                         <label>
//                             <input
//                                 type="checkbox"
//                                 name="interests"
//                                 value={interest}
//                                 checked={selectedInterests.includes(interest)}
//                                 onChange={handleChange}
//                             />
//                             {interest}
//                         </label>
//                     </div>
//                 ))}
//                 <div className="checkbox-item">
//                     <label>
//                         <input
//                             type="checkbox"
//                             name="interests"
//                             value="Other"
//                             checked={selectedInterests.includes('Other')}
//                             onChange={handleChange}
//                         />
//                         Other
//                     </label>
//                     {selectedInterests.includes('Other') && (
//                         <input
//                             type="text"
//                             name="otherInterest"
//                             value={formData.otherInterest}
//                             onChange={handleOtherOptionChange}
//                             placeholder="Please specify"
//                         />
//                     )}
//                 </div>
//             </div>

//             {/* Lifestyle Section */}
//             <div className="checkbox-section">
//                 <label>Lifestyle:</label>
//                 {lifestyleList.map((lifestyle) => (
//                     <div key={lifestyle} className="checkbox-item">
//                         <label>
//                             <input
//                                 type="checkbox"
//                                 name="lifestyle"
//                                 value={lifestyle}
//                                 checked={selectedLifestyle.includes(lifestyle)}
//                                 onChange={handleChange}
//                             />
//                             {lifestyle}
//                         </label>
//                     </div>
//                 ))}
//                 <div className="checkbox-item">
//                     <label>
//                         <input
//                             type="checkbox"
//                             name="lifestyle"
//                             value="Other"
//                             checked={selectedLifestyle.includes('Other')}
//                             onChange={handleChange}
//                         />
//                         Other
//                     </label>
//                     {selectedLifestyle.includes('Other') && (
//                         <input
//                             type="text"
//                             name="otherLifestyle"
//                             value={formData.otherLifestyle}
//                             onChange={handleOtherOptionChange}
//                             placeholder="Please specify"
//                         />
//                     )}
//                 </div>
//             </div>

//             {/* Background Input */}
//             <div className="input-section">
//                 <label>Background:</label>
//                 <input type="text" name="background" value={formData.background} onChange={handleChange} />
//             </div>

//             {/* State Input */}
//             <div className="input-section">
//                 <label>State:</label>
//                 <input type="text" name="state" value={formData.state} onChange={handleChange} />
//             </div>

//             {/* City Input */}
//             <div className="input-section">
//                 <label>City:</label>
//                 <input type="text" name="city" value={formData.city} onChange={handleChange} />
//             </div>

//             {/* Caste Input */}
//             <div className="input-section">
//                 <label>Caste:</label>
//                 <input type="text" name="caste" value={formData.caste} onChange={handleChange} />
//             </div>

//             {/* Mobile No Input */}
//             <div className="input-section">
//                 <label>Mobile No:</label>
//                 <input type="text" name="mobileNo" value={formData.mobileNo} onChange={handleChange} />
//             </div>

//             <button type="submit" className="submit-button">Submit</button>
//         </form>
//         </>
//     );
// };

// export default AdditionalDetailsPage;




// import React, { useState } from 'react';
// import '../CSS/AdditionalDetailsPage.css';
// import Navbar from './Navbar';
// import axiosURL from '../axiosURL';
// import { useNavigate } from 'react-router-dom';

// const AdditionalDetailsPage = () => {
//     const navigate = useNavigate();
//     const [formData, setFormData] = useState({
//         height: '',
//         annualIncome: '',
//         occupation: '',
//         smallBio: '',
//         aboutYourself: '',
//         hobbies: [],
//         interests: [],
//         lifestyle: [],
//         otherHobby: '',
//         otherInterest: '',
//         otherLifestyle: '',
//         background: '',
//         state: '',
//         city: '',
//         caste: '',
//         mobileNo: '',
//     });

//     const [selectedHobbies, setSelectedHobbies] = useState([]);
//     const [selectedInterests, setSelectedInterests] = useState([]);
//     const [selectedLifestyle, setSelectedLifestyle] = useState([]);

//     const hobbiesList = ['Reading', 'Traveling', 'Cooking', 'Sports', 'Music'];
//     const interestsList = ['Technology', 'Art', 'Fitness', 'Nature', 'Science'];
//     const lifestyleList = ['Healthy Eating', 'Outdoor Activities', 'Minimalist', 'Luxury', 'Traditional'];

//     const handleChange = (e) => {
//         const { name, value, type } = e.target;

//         if (type === 'checkbox') {
//             const { checked, value } = e.target;
//             if (name === 'hobbies') {
//                 setSelectedHobbies((prev) =>
//                     checked
//                         ? [...prev, value]
//                         : prev.filter((item) => item !== value)
//                 );
//             } else if (name === 'interests') {
//                 setSelectedInterests((prev) =>
//                     checked
//                         ? [...prev, value]
//                         : prev.filter((item) => item !== value)
//                 );
//             } else if (name === 'lifestyle') {
//                 setSelectedLifestyle((prev) =>
//                     checked
//                         ? [...prev, value]
//                         : prev.filter((item) => item !== value)
//                 );
//             }
//         } else {
//             setFormData({ ...formData, [name]: value });
//         }
//     };

//     const handleOtherOptionChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const dataToSend = {
//             ...formData,
//             hobbies: selectedHobbies.join(','), 
//             interests: selectedInterests.join(','),
//             lifestyle: selectedLifestyle.join(','),
//         };

//         try {
//             const response = await axiosURL.post('/user/user-additional-details', dataToSend);
//             setTimeout(() => {
//                 alert('Additional details submitted successfully');
//                 navigate('/profilesection');
//             }, 500);
//         } catch (error) {
//             console.error('Error submitting additional details:', error);
//         }
//     };

//     const handleSkip = () => {
//         navigate('/profilesection'); // Route to skip the additional details
//     };

//     const handleBack = () => {
//         navigate(-1); // Go back to the previous page
//     };

//     return (
//         <>
//             <Navbar />
//             <form onSubmit={handleSubmit} className="additional-details-form">
//                 {/* Back and Skip Buttons */}
//                 <div className="navigation-buttons">
//                     <button type="button" className="back-button" onClick={handleBack}>Back</button>
//                     <button type="button" className="skip-button" onClick={handleSkip}>Skip</button>
//                 </div>

//                 <h1>Additional Details</h1>
                
//                 {/* Form Fields */}
//                 <div className="input-section">
//                     <label>Height (in cm):</label>
//                     <input type="number" name="height" value={formData.height} onChange={handleChange} />
//                 </div>
                
//                 <div className="input-section">
//                     <label>Annual Income:</label>
//                     <input type="number" name="annualIncome" value={formData.annualIncome} onChange={handleChange} />
//                 </div>

//                 {/* Add the rest of the input sections and checkboxes like before */}
//                 {/* Submit Button */}
//                 <button type="submit" className="submit-button">Submit</button>
//             </form>
//         </>
//     );
// };

// export default AdditionalDetailsPage;






import React, { useState } from 'react';
import '../CSS/AdditionalDetailsPage.css';
import Navbar from './Navbar';
import axiosURL from '../axiosURL';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const AdditionalDetailsPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        height: '',
        annualIncome: '',
        occupation: '',
        smallBio: '',
        aboutYourself: '',
        hobbies: [],
        interests: [],
        lifestyle: [],
        otherHobby: '',
        otherInterest: '',
        otherLifestyle: '',
        background: '',
        state: '',
        city: '',
        caste: '',
        mobileNo: '',
    });

    const [selectedHobbies, setSelectedHobbies] = useState([]);
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [selectedLifestyle, setSelectedLifestyle] = useState([]);

    const hobbiesList = ['Reading', 'Traveling', 'Cooking', 'Sports', 'Music'];
    const interestsList = ['Technology', 'Art', 'Fitness', 'Nature', 'Science'];
    const lifestyleList = ['Healthy Eating', 'Outdoor Activities', 'Minimalist', 'Luxury', 'Traditional'];

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        if (type === 'checkbox') {
            if (name === 'hobbies') {
                setSelectedHobbies((prev) =>
                    e.target.checked
                        ? [...prev, value]
                        : prev.filter((item) => item !== value)
                );
            } else if (name === 'interests') {
                setSelectedInterests((prev) =>
                    e.target.checked
                        ? [...prev, value]
                        : prev.filter((item) => item !== value)
                );
            } else if (name === 'lifestyle') {
                setSelectedLifestyle((prev) =>
                    e.target.checked
                        ? [...prev, value]
                        : prev.filter((item) => item !== value)
                );
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleOtherOptionChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSend = {
            ...formData,
            hobbies: selectedHobbies.join(','), // Convert array to comma-separated string
            interests: selectedInterests.join(','),
            lifestyle: selectedLifestyle.join(','),
        };

        try {
            const response = await axiosURL.post('/user/user-additional-details', dataToSend);
            console.log('response:', response);
            alert('Additional details submitted successfully');
            navigate('/profilesection');
        } catch (error) {
            console.error('Error submitting additional details:', error);
        }
    };

    const handleBack = () => {
        navigate('/previousPage'); // Replace with the actual route for the previous page
    };

    const handleSkip = () => {
        navigate('/nextPage'); // Replace with the actual route for the next page
    };

    return (
        <>
            <Navbar />
            

            <form onSubmit={handleSubmit} className="additional-details-form">
            <div className="navigation-buttons">
                <button className="back-button" onClick={handleBack}><FontAwesomeIcon icon="fas fa-step-backward" />Back</button>
                <button className="skip-button" onClick={handleSkip}>Skip<FontAwesomeIcon icon="fas fa-step-forward" /></button>
            </div>
                <h1>Additional Details</h1>

                {/* Height Input */}
                <div className="input-section">
                    <label>Height (in cm):</label>
                    <input type="number" name="height" value={formData.height} onChange={handleChange} />
                </div>

                {/* Annual Income Input */}
                <div className="input-section">
                    <label>Annual Income:</label>
                    <input type="number" name="annualIncome" value={formData.annualIncome} onChange={handleChange} />
                </div>

                {/* Occupation Input */}
                <div className="input-section">
                    <label>Occupation:</label>
                    <input type="text" name="occupation" value={formData.occupation} onChange={handleChange} />
                </div>

                {/* Small Bio Input */}
                <div className="input-section">
                    <label>Small Bio (10 words):</label>
                    <input type="text" name="smallBio" value={formData.smallBio} onChange={handleChange} />
                </div>

                {/* About Yourself Input */}
                <div className="input-section">
                    <label>About Yourself (50-100 words):</label>
                    <textarea name="aboutYourself" value={formData.aboutYourself} onChange={handleChange} />
                </div>

                {/* Hobbies Section */}
                <div className="checkbox-section">
                    <label>Hobbies:</label>
                    {hobbiesList.map((hobby) => (
                        <div key={hobby} className="checkbox-item">
                            <label>
                                <input
                                    type="checkbox"
                                    name="hobbies"
                                    value={hobby}
                                    checked={selectedHobbies.includes(hobby)}
                                    onChange={handleChange}
                                />
                                {hobby}
                            </label>
                        </div>
                    ))}
                    <div className="checkbox-item">
                        <label>
                            <input
                                type="checkbox"
                                name="hobbies"
                                value="Other"
                                checked={selectedHobbies.includes('Other')}
                                onChange={handleChange}
                            />
                            Other
                        </label>
                        {selectedHobbies.includes('Other') && (
                            <input
                                type="text"
                                name="otherHobby"
                                value={formData.otherHobby}
                                onChange={handleOtherOptionChange}
                                placeholder="Please specify"
                            />
                        )}
                    </div>
                </div>

                {/* Interests Section */}
                <div className="checkbox-section">
                    <label>Interests:</label>
                    {interestsList.map((interest) => (
                        <div key={interest} className="checkbox-item">
                            <label>
                                <input
                                    type="checkbox"
                                    name="interests"
                                    value={interest}
                                    checked={selectedInterests.includes(interest)}
                                    onChange={handleChange}
                                />
                                {interest}
                            </label>
                        </div>
                    ))}
                    <div className="checkbox-item">
                        <label>
                            <input
                                type="checkbox"
                                name="interests"
                                value="Other"
                                checked={selectedInterests.includes('Other')}
                                onChange={handleChange}
                            />
                            Other
                        </label>
                        {selectedInterests.includes('Other') && (
                            <input
                                type="text"
                                name="otherInterest"
                                value={formData.otherInterest}
                                onChange={handleOtherOptionChange}
                                placeholder="Please specify"
                            />
                        )}
                    </div>
                </div>

                {/* Lifestyle Section */}
                <div className="checkbox-section">
                    <label>Lifestyle:</label>
                    {lifestyleList.map((lifestyle) => (
                        <div key={lifestyle} className="checkbox-item">
                            <label>
                                <input
                                    type="checkbox"
                                    name="lifestyle"
                                    value={lifestyle}
                                    checked={selectedLifestyle.includes(lifestyle)}
                                    onChange={handleChange}
                                />
                                {lifestyle}
                            </label>
                        </div>
                    ))}
                    <div className="checkbox-item">
                        <label>
                            <input
                                type="checkbox"
                                name="lifestyle"
                                value="Other"
                                checked={selectedLifestyle.includes('Other')}
                                onChange={handleChange}
                            />
                            Other
                        </label>
                        {selectedLifestyle.includes('Other') && (
                            <input
                                type="text"
                                name="otherLifestyle"
                                value={formData.otherLifestyle}
                                onChange={handleOtherOptionChange}
                                placeholder="Please specify"
                            />
                        )}
                    </div>
                </div>

                {/* Background Input */}
                <div className="input-section">
                    <label>Background:</label>
                    <input type="text" name="background" value={formData.background} onChange={handleChange} />
                </div>

                {/* State Input */}
                <div className="input-section">
                    <label>State:</label>
                    <input type="text" name="state" value={formData.state} onChange={handleChange} />
                </div>

                {/* City Input */}
                <div className="input-section">
                    <label>City:</label>
                    <input type="text" name="city" value={formData.city} onChange={handleChange} />
                </div>

                {/* Caste Input */}
                <div className="input-section">
                    <label>Caste:</label>
                    <input type="text" name="caste" value={formData.caste} onChange={handleChange} />
                </div>

                {/* Mobile No Input */}
                <div className="input-section">
                    <label>Mobile No:</label>
                    <input type="text" name="mobileNo" value={formData.mobileNo} onChange={handleChange} />
                </div>

                <button type="submit" className="submit-button">Submit</button>
            </form>
        </>
    );
};

export default AdditionalDetailsPage;
