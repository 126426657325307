import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import HomePage from './Component/HomePage';
import Profiles from './Component/Profiles';
import ProfileDetails from './Component/ProfileDetails';
import AboutUs from './Component/AboutUs';
import ContactUs from './Component/ContactUs';
import Registration from './Component/Registration';
import Login from './Component/Login';
import ForgotPassword from './Component/ForgotPassword';
import UpgradeMembership from './Component/UpgradeMembership';
import ProfileSection from './Component/ProfileSection_c';
// import FullProfile from './Component/FullProfile';
import AdditionalDetailsPage from './Component/AdditionalDetailsPage';
import PayNow from './Component/PayNow';

import FullProf from './Component/FullProf';
import EditProfile from './Component/EditProfile';

function App() {
    // const backgroundStyle = {
    //     backgroundImage: url('../Images//pink-cloud.png'),
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     minHeight: '100vh',
        
    //   };

    const userId = 'User1234';
    const registrationDetails = 'Registered on: 01-Jan-2024';

    return (
        <Router>
            {/* <Navbar /> */}

            <div className="content">
                <Routes>
                <Route path="Navbar" element={<Navbar/>} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/profiles" element={<Profiles />} />
                    <Route path="/profilesection" element={<ProfileSection />} />
                    <Route path="/profile/:id" element={<ProfileDetails />} />
                    <Route path="/AboutUs" element={<AboutUs />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/register" element={<Registration />} />
                    <Route path="/Login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    {/* <Route path="/EditProfile" element={<EditProfile />} /> */}
                    <Route path="/edit-profile/:userId" element={<EditProfile />} />

                    {/* <Route path="/full-profile/:id" element={<FullProfile />} /> */}
                    <Route path="/additionaldetails" element={<AdditionalDetailsPage />} />
                    <Route path="/PayNow" element={<PayNow />} />
                    {/* <Route path="/Navbar1" element={<Navbar1/>} />
                    <Route path="/Navbar1" element={<Navbar1/>} /> */}
                    <Route path="/FullProf/:id" element={<FullProf/>} />
                    <Route 
            path="/upgrade-membership" 
            element={<UpgradeMembership userId={userId} registrationDetails={registrationDetails} />} 
          />
                </Routes>
            </div>
            {/* <Footer /> */}
        </Router>
    );
}

export default App;

