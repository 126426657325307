import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosURL from '../axiosURL';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import '../CSS/EditProfile.css'; // Import custom CSS for styling

const EditProfile = () => {
  const { userId } = useParams(); 
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    mobileNo: '',
    occupation: '',
    city: '',
    bio: '',
    profileImage: '', // Add profileImage field
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axiosURL.get(`/user/${userId}`);
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
    fetchProfileData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData((prevState) => ({
          ...prevState,
          profileImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosURL.put(`/user/${userId}, profileData`);
      alert('Profile updated successfully!');
      navigate(`/profile`);
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  return (
    <div className="edit-profile-page">
      <Container className="edit-profile-container">
        <h2 className="edit-profile-title">Edit Your Profile</h2>

        {/* Circular Profile Image */}
        <div className="profile-image-container">
          <img
            src={profileData.profileImage || 'https://via.placeholder.com/150'}
            alt="Profile"
            className="profile-image"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="image-upload-input"
          />
        </div>

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName" className="form-group-custom">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={profileData.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName" className="form-group-custom">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="age" className="form-group-custom">
                <Form.Label>Age</Form.Label>
                <Form.Control
                  type="number"
                  name="age"
                  value={profileData.age}
                  onChange={handleChange}
                  placeholder="Enter your age"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="gender" className="form-group-custom">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={profileData.gender}
                  onChange={handleChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="mobileNo" className="form-group-custom">
                <Form.Label>Mobile No</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNo"
                  value={profileData.mobileNo}
                  onChange={handleChange}
                  placeholder="Enter mobile number"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="occupation" className="form-group-custom">
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                  type="text"
                  name="occupation"
                  value={profileData.occupation}
                  onChange={handleChange}
                  placeholder="Enter your occupation"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="city" className="form-group-custom">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={profileData.city}
                  onChange={handleChange}
                  placeholder="Enter city"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="bio" className="form-group-custom">
                <Form.Label>Bio</Form.Label>
                <Form.Control
                  as="textarea"
                  name="bio"
                  value={profileData.bio}
                  onChange={handleChange}
                  placeholder="Tell us about yourself"
                />
              </Form.Group>
            </Col>
          </Row>
          <Button type="submit" className="save-btn">
            Save Changes
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default EditProfile;
