// src/components/ForgotPasswordPage.js
import React, { useState } from 'react';
import '../CSS/ForgotPassword.css';
import Footer from './Footer';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Please check the reset link on your registered email.');
        setEmail(''); // Clear email field after submission
    };

    return (
        <>
        <div className="forgot-password-container">
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit} className='form-in'>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
        <div className="footer-atlast"><Footer/></div>
        
        </>
    );
};

export default ForgotPassword;
