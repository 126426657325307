// import React, { useState ,useEffect} from 'react';
// import '../CSS/ProfileSection_c.css';
// import "bootstrap/dist/css/bootstrap.min.css"; 
// import { Button, Card, Col, Container, Row, Modal } from 'react-bootstrap';
// import UpgradeMembership from './UpgradeMembership';
// import Footer from './Footer';
// import { useNavigate } from 'react-router-dom';
// import SearchBar from './SearchBar'; 
// import Navbar from './Navbar';
// import axiosURL from '../axiosURL';

// const ProfileSection = () => {
//     const navigate = useNavigate();
//     const [userId, setUserId] = useState('');
//     const [personalInfo, setPersonalInfo] = useState({});
//     const [showUpgrade, setShowUpgrade] = useState(false);
//     const [showMoreMatches, setShowMoreMatches] = useState(false);
//     const [selectedMatch, setSelectedMatch] = useState(null);
//     const [filteredMatches, setFilteredMatches] = useState([]);
//     const [loggedInUserId, setLoggedInUserId] = useState(null); // Store logged-in user's ID
//     const [profilesViewed, setProfilesViewed] = useState(0);


//     useEffect(() => {
//         // Simulate generating userId or retrieve it from localStorage/session
//         const storedUserId = localStorage.getItem('userId');
//         console.log('stored User Id : ' , storedUserId);
        
//         if(storedUserId){
//             // Fetch actual user data from the backend using userId
//             setUserId(storedUserId);
//             const fetchUserProfile = async () =>{

//                 try {
//                     const response = await axiosURL.get(`/user/${storedUserId}`); // Make sure this is your actual API endpoint
//                     console.log('response..by user Id .' , response.data);
//                     setPersonalInfo(response.data); // Store fetched user data
//                     console.log("subscriptions status...",response.data.subscriptionStatus);
//                 } catch (error) {
//                     console.error('Error fetching user profile:', error);
//                 }
//             };

//             fetchUserProfile();
//         }else {
//             // If no userId is found, redirect to login
//             navigate('/login');
//         }
//     },[navigate]);

//     useEffect(() =>{
//         // Fetch the logged-in user's ID (assuming it's stored in localStorage)
//         const storedUserId = localStorage.getItem('userId'); // Replace with your logic

//         console.log('store id ' , storedUserId);

//          // Fetch all users data (match data)
//          const fetchAllUsers = async () => {
//             try {
//                 const response = await axiosURL.get('/user/getAllUsers');
//                 console.log('All users : ' , response.data);

//                 if(response.data){
//                     const filteredUsers = response.data.filter(user => user.id !== parseInt(storedUserId, 10));

//                     console.log('filterusers' ,filteredUsers);
//                     console.log('log store id ' , storedUserId);

//                     setFilteredMatches(filteredUsers);
//                 }else {
//                     console.log("No users found");
//                 }
//             } catch (error) {
//                 console.error('Error fetching matches:', error);
//             }
//          };
//         fetchAllUsers();

//     }, []);


//     const toggleUpgrade = () => {
//         setShowUpgrade(!showUpgrade);
//     };

//     const toggleShowMoreMatches = () => {
//         setShowMoreMatches(!showMoreMatches);
//     };

//     const handleViewProfile = (match) => {
//         let maxProfileAllowed;
//         //Set maximum profiles allowed based on user subscription
//         console.log("personal Info Sub Status .. " ,  personalInfo.subscriptionStatus);
//         if(personalInfo.subscriptionStatus === 'none'){
//             maxProfileAllowed = 2; //free Users
//         } else if(personalInfo.subscriptionStatus === 'premium'){
//             maxProfileAllowed = 20; //Premium Users
//         }else if(personalInfo.subscriptionStatus === 'exclusive'){
//             maxProfileAllowed = 40; //Exclusive Users 
//         }

//         // Check if the user has reached their profile view limit
//         if(profilesViewed < maxProfileAllowed){
//             setSelectedMatch(match);  // Set the match for viewing
//             setProfilesViewed(profilesViewed + 1); // Increment profiles viewed count
//         } else {
//              // Show alert once the user reaches their limit and redirect them to the upgrade page
//              alert(`You have reached the limit of ${maxProfileAllowed} profiles. Please upgrade to view more profiles.`);
//              navigate('/upgrade-membership');  // Navigate to upgrade page
//         }
        
//     };
//     const seeMoreViewProfile = (match) => {
//         setSelectedMatch(match);
//         navigate(`/FullProf/${selectedMatch?.id}`, {state:match});
//     };

//     const handleCloseProfile = () => {
//         setSelectedMatch(null);
//     };

//     return (
//         <>
//         <Navbar/>
//         <div>
//             <Container className="mt-4">
//                 <Row>
//                     <Col>
//                         <Card className="profile-card mb-4">
//                         <h1>Your Profile</h1>
//                             <Card.Body>
//                                 <Card.Title>{personalInfo.firstName} {personalInfo.lastName}</Card.Title>
//                                 <Card.Text>
//                                     Age: {personalInfo.age}<br />
//                                     Gender: {personalInfo.gender}<br />
//                                     Mobile: {personalInfo.userAddtionalDetails?.mobileNo}<br />
//                                     Occupation: {personalInfo.userAddtionalDetails?.occupation}<br />
//                                     Bio: {personalInfo.userAddtionalDetails?.city}
//                                 </Card.Text>
//                                 <Button variant="primary" onClick={toggleUpgrade}>Upgrade Now</Button>
//                             </Card.Body>
//                         </Card>

//                         <SearchBar data={filteredMatches} setResults={setFilteredMatches} />  {/* Pass the data and setter function */}

//                         <h3>Match Suggestions</h3>
//                         <Row>
//                             {filteredMatches.slice(0, showMoreMatches ? filteredMatches.length : 6).map((match) => (
//                                 <Col sm={12} md={6} lg={4} key={match.id}>
//                                     <Card className="mb-4">
//                                         {match.profilePhoto ? (
//                                             <Card.Img 
//                                             variant = "top" 
//                                             src = {`data:${match.profilePhotoContentType};base64,${match.profilePhoto}`}
//                                             alt = {`${match.firstName}'s profiles`}
//                                             style={{ width: '100%', height: '400px', objectFit: 'cover' }}
//                                              />
//                                         ) : (
//                                             <Card.Img
//                                             variant="top" 
//                                             src="/default-profile.png" // default image when profilePhoto is unavailable
//                                             alt="Default Profile" 
//                                             />
//                                         )} 
//                                         <Card.Body>
//                                             <Card.Title>{match.firstName}</Card.Title>
//                                             <Card.Text>
//                                                 Age: {match.age}<br />
//                                                 Height: {match.height || 'Not Available'} cm<br />
//                                                 Education: {match.occupation || 'Not Available'} <br/>
//                                                 Religion: {match.religion}<br />
//                                                 Annual Income: ₹{match.annualIncome || 'Not Available'}
//                                             </Card.Text>
//                                             <Button variant="info" onClick={() => handleViewProfile(match)}>View Profile</Button>
//                                         </Card.Body>
//                                     </Card>
//                                 </Col>
//                             ))}
//                         </Row>

//                         {filteredMatches.length > 6 && (
//                             <Button variant="primary" onClick={toggleShowMoreMatches}>
//                                 {showMoreMatches ? 'Show Less' : 'Show More'}
//                             </Button>
//                         )}

//                         <Modal show={showUpgrade} onHide={toggleUpgrade}>
//                             <Modal.Header closeButton>
//                                 <Modal.Title>Upgrade Membership</Modal.Title>
//                             </Modal.Header>
//                             <Modal.Body>
//                                 <UpgradeMembership />
//                             </Modal.Body>
//                         </Modal>

//                         <Modal show={selectedMatch !== null} onHide={handleCloseProfile}>
//                             <Modal.Header closeButton>
//                                 <Modal.Title>{selectedMatch?.firstName}'s Profile</Modal.Title>
//                             </Modal.Header>
//                             <Modal.Body>
//                                 {selectedMatch && (
//                                     <div>
//                                         {selectedMatch.profilePhoto ? (
//                                         <img 
//                                             src={`data:${selectedMatch.profilePhotoContentType};base64,${selectedMatch.profilePhoto}`} 
//                                             alt={`${selectedMatch.firstName}'s Profile`}
//                                             className="img-fluid mb-3"
//                                             style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }} 
//                                         />
//                                         ) : (
//                                         <p>No Profile Photo</p>
//                                         )}
//                                         <p><strong>Age:</strong> {selectedMatch.age}</p>
//                                         <p><strong>Height:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.height || 'Not Available') : 'Details Not Found'} cm</p>
//                                         <p><strong>Education:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.occupation || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Religion:</strong> {selectedMatch.religion}</p>
//                                         <p><strong>Annual Income:</strong> ₹{selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.annualIncome || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Occupation:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.occupation || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Bio:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.smallBio || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>About Yourself:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.aboutYourself || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Hobbies:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.hobbies || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Interests:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.interests || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Lifestyle:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.lifestyle || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Background:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.background || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Caste:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.caste || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>State:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.state || 'Not Available') : 'Details Not Found'}</p>
//                                         <p><strong>Contact Details:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.mobileNo || 'Not Available') : 'Details Not Found'}</p>
//                                     </div>
//                                 )}
//                             </Modal.Body>
//                             <Modal.Footer>
//                                  {/* Show "See More" only if the user has a subscription and hasn't reached the limit */}
//                                  {personalInfo.subscription === 'premium' && profilesViewed < 20 && (
//                                     <Button variant="secondary" onClick={() => seeMoreViewProfile(selectedMatch)}>See More</Button>
//                                  )}
//                                  {personalInfo.subscription === 'exclusive' && profilesViewed < 40 && (
//                                     <Button variant="secondary" onClick={() => seeMoreViewProfile(selectedMatch)}>See More</Button>
//                                  )}
//                                  {personalInfo.subscription === 'none' && profilesViewed >= 2 && (
//                                     <p>Please upgrade to see more.</p>
//                                  )}
                                
//                                 <Button variant="secondary" onClick={handleCloseProfile}>Close</Button>
//                             </Modal.Footer>
//                         </Modal>
//                     </Col>
//                 </Row>
//             </Container>
//             <Footer />
//         </div>
//     </>
//     );
// };

// export default ProfileSection;

import React, { useState, useEffect } from 'react';
import '../CSS/ProfileSection_c.css';
import "bootstrap/dist/css/bootstrap.min.css"; 
import { Button, Card, Col, Container, Row, Modal } from 'react-bootstrap';
import UpgradeMembership from './UpgradeMembership';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar'; 
import Navbar from './Navbar';
import axiosURL from '../axiosURL';

const ProfileSection = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [personalInfo, setPersonalInfo] = useState({});
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [showMoreMatches, setShowMoreMatches] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [loggedInUserId, setLoggedInUserId] = useState(null); 
    const [profilesViewed, setProfilesViewed] = useState(0);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
            const fetchUserProfile = async () => {
                try {
                    const response = await axiosURL.get(`/user/${storedUserId}`);
                    setPersonalInfo(response.data);
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                }
            };
            fetchUserProfile();
        } else {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const fetchAllUsers = async () => {
            try {
                const response = await axiosURL.get('/user/getAllUsers');
                const filteredUsers = response.data.filter(user => user.id !== parseInt(storedUserId, 10));
                setFilteredMatches(filteredUsers);
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };
        fetchAllUsers();
    }, []);

    const toggleUpgrade = () => {
        setShowUpgrade(!showUpgrade);
    };

    const toggleShowMoreMatches = () => {
        setShowMoreMatches(!showMoreMatches);
    };

    const handleViewProfile = (match) => {
        let maxProfileAllowed;
        if (personalInfo.subscriptionStatus === 'none') {
            maxProfileAllowed = 2;
        } else if (personalInfo.subscriptionStatus === 'premium') {
            maxProfileAllowed = 20;
        } else if (personalInfo.subscriptionStatus === 'exclusive') {
            maxProfileAllowed = 40;
        }

        if (profilesViewed < maxProfileAllowed) {
            setSelectedMatch(match);
            setProfilesViewed(profilesViewed + 1);
        } else {
            alert(`You have reached the limit of ${maxProfileAllowed} profiles. Please upgrade to view more profiles.`);
            navigate('/upgrade-membership');
        }
    };

    const seeMoreViewProfile = (match) => {
        setSelectedMatch(match);
        navigate(`/FullProf/${selectedMatch?.id}`, { state: match });
    };

    const handleCloseProfile = () => {
        setSelectedMatch(null);
    };

    const handleEditProfile = () => {
        navigate(`/edit-profile/${userId}`); // Redirect to the edit profile page with userId
    };
    

    return (
        <>
            <Navbar />
            <div>
                <Container className="mt-4">
                    <Row>
                        <Col>
                            <Card className="profile-card mb-4">
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 id='profile-heading'><center>Your&nbsp;Profile</center></h1>

                                        <Button variant="outline-primary" onClick={handleEditProfile} id= "btn-edit">
                                            Edit Profile
                                        </Button>
                                    </div>
                                    <Card.Title>{personalInfo.firstName} {personalInfo.lastName}</Card.Title>
                                    <Card.Text>
                                        Age: {personalInfo.age}<br />
                                        Gender: {personalInfo.gender}<br />
                                        Mobile: {personalInfo.userAddtionalDetails?.mobileNo}<br />
                                        Occupation: {personalInfo.userAddtionalDetails?.occupation}<br />
                                        Bio: {personalInfo.userAddtionalDetails?.city}
                                    </Card.Text>
                                    <Button variant="primary" onClick={toggleUpgrade}>Upgrade Now</Button>
                                </Card.Body>
                            </Card>

                            <SearchBar data={filteredMatches} setResults={setFilteredMatches} />

                            <h3>Match Suggestions</h3>
                            <Row>
                                {filteredMatches.slice(0, showMoreMatches ? filteredMatches.length : 6).map((match) => (
                                    <Col sm={12} md={6} lg={4} key={match.id}>
                                        <Card className="mb-4">
                                            {match.profilePhoto ? (
                                                <Card.Img 
                                                    variant="top" 
                                                    src={`data:${match.profilePhotoContentType};base64,${match.profilePhoto}`} 
                                                    alt={`${match.firstName}'s profile`} 
                                                    style={{ width: '100%', height: '400px', objectFit: 'cover' }} 
                                                />
                                            ) : (
                                                <Card.Img 
                                                    variant="top" 
                                                    src="/default-profile.png" 
                                                    alt="Default Profile" 
                                                />
                                            )}
                                            <Card.Body>
                                                <Card.Title>{match.firstName}</Card.Title>
                                                <Card.Text>
                                                    Age: {match.age}<br />
                                                    Height: {match.height || 'Not Available'} cm<br />
                                                    Education: {match.occupation || 'Not Available'}<br />
                                                    Religion: {match.religion}<br />
                                                    Annual Income: ₹{match.annualIncome || 'Not Available'}
                                                </Card.Text>
                                                <Button variant="info" onClick={() => handleViewProfile(match)}>
                                                    View Profile
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>

                            {filteredMatches.length > 6 && (
                                <Button variant="primary" onClick={toggleShowMoreMatches}>
                                    {showMoreMatches ? 'Show Less' : 'Show More'}
                                </Button>
                            )}

                            <Modal show={showUpgrade} onHide={toggleUpgrade}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Upgrade Membership</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <UpgradeMembership />
                                </Modal.Body>
                            </Modal>

                            <Modal show={selectedMatch !== null} onHide={handleCloseProfile}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{selectedMatch?.firstName}'s Profile</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedMatch && (
                                        <div>
                                            {selectedMatch.profilePhoto ? (
                                                <img 
                                                    src={`data:${selectedMatch.profilePhotoContentType};base64,${selectedMatch.profilePhoto}`} 
                                                    alt={`${selectedMatch.firstName}'s Profile`} 
                                                    className="img-fluid mb-3" 
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }} 
                                                />
                                            ) : (
                                                <p>No Profile Photo</p>
                                            )}
                                            <p><strong>Age:</strong> {selectedMatch.age}</p>
                                            <p><strong>Height:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.height || 'Not Available') : 'Details Not Found'} cm</p>
                                            <p><strong>Education:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.occupation || 'Not Available') : 'Details Not Found'}</p>
                                            <p><strong>Religion:</strong> {selectedMatch.religion}</p>
                                            <p><strong>Annual Income:</strong> ₹{selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.annualIncome || 'Not Available') : 'Details Not Found'}</p>
                                            <p><strong>Occupation:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.occupation || 'Not Available') : 'Details Not Found'}</p>
                                            <p><strong>Bio:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.smallBio || 'Not Available') : 'Details Not Found'}</p>
                                            <p><strong>About Yourself:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.aboutYourself || 'Not Available') : 'Details Not Found'}</p>
                                            <p><strong>Hobbies:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.hobbies || 'Not Available') : 'Details Not Found'}</p>
                                            <p><strong>Interests:</strong> {selectedMatch.userAddtionalDetails ? (selectedMatch.userAddtionalDetails.interests || 'Not Available') : 'Details Not Found'}</p>
                                            <Button variant="secondary" onClick={handleCloseProfile}>Close</Button>
                                        </div>
                                    )}
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default ProfileSection;
