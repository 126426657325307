import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaBars } from 'react-icons/fa';
// import logo from '../Images/logo1-removebg-preview'; // Adjust path as per your project structure
import logo from '../Images/logo1-removebg-preview.png'

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in (e.g., by checking for a token in localStorage)
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // Remove token and update login state
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>
        <button className="navbar-toggler" onClick={toggleNavbar}>
          <FaBars className="fa-bars" />
        </button>
        <ul className={`nav-links ${isOpen ? 'show' : ''}`}>
          <li>
            <Link to="/">Home</Link>
          </li>
         {/* <li>
            <Link to="/additionaldetails">AdditionalDetailsPage</Link>
          </li> */}
          
          {/* <li>
          <Link to="/profilesection">profile</Link>
          </li> <li>
            <Link to="/upgrade-membership">upgrade</Link>
          </li>  */}
          <li>
            <Link to="/AboutUs">About Us</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          {/* <li>
            <Link to="/PayNow">paynow</Link>
          </li> */}


          {/* Display these links only if the user is logged in */}
          {isLoggedIn ? (
            <>
              <li>
                <Link to="/profilesection">Profiles</Link>
              </li>
              <li>
                <button className="logout-btn" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </>
          ) : (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;